<template>
  <div class="home-page">
    <p class="title">工作概要</p>
    <div class="flex justify-between">
      <div class="summary">
        <div class="summary-list" :class="{ list_active: item.isEnter }" @mouseover="item.isEnter = true" @mouseout="item.isEnter = false" v-for="item in outline" :key="item.name">
          <p class="list_title">{{ item.name }}</p>
          <p class="list_num">{{ statisticData[item.type] }}</p>
          <img class="list_img" :src="item.isEnter ? item.enterImg : item.img" alt="" />
        </div>
      </div>
      <div class="information">
        <!-- <img class="information-icon" :src="iconUrl('edit')" alt="" /> -->
        <div class="information-bgColor"></div>
        <img class="information-avatar" :src="userAvatar" alt="" />
        <p class="information-name">{{ userName }}</p>
        <p class="information-identity">法务部成员</p>
      </div>
    </div>
    <div class="flex justify-between">
      <p class="case-title">案件信息</p>
      <p class="announcement-title">
        <span class="title">系统公告</span>
        <span>查看更多》</span>
      </p>
    </div>
    <div class="flex justify-between">
      <div class="case">
        <div class="but-container-padding flex justify-between">
          <div class="but-container">
            <p class="but" :class="{ 'but-active': caseType === index }" v-for="(item, index) in ['进行中', '已完成']" :key="item" @click="changeCaseType(index)">
              {{ item }}
            </p>
          </div>
          <div class="search-case">
            <div class="search-btn" @click="search"></div>
            <input type="text" name="" v-model="listParam.search" class="search" placeholder="请输入关键词" />
          </div>
        </div>
        <c-table :data="caseData">
          <el-table-column align="center" type="index" width="50"> </el-table-column>
          <el-table-column align="center" prop="username" label="联系人"> </el-table-column>
          <el-table-column align="center" prop="mobile" label="电话"> </el-table-column>
          <el-table-column align="center" prop="all" label="年龄"> </el-table-column>
          <el-table-column align="center" prop="sexlist" label="性别"> </el-table-column>
          <el-table-column align="center" prop="sourcelist" label="客户来源"> </el-table-column>
          <el-table-column align="center" prop="startime" label="开庭时间">
            <template #default="{ row }">
              <p v-if="row.startime">{{ row.startime.split(' ')[0] }}</p>
            </template>
          </el-table-column>
        </c-table>
      </div>
      <div class="announcement">
        <div class="list list-active" v-for="item in announcementList" :key="item.id">
          <i class="dit"></i>
          <p class="txt">{{ item.content }}</p>
          <p class="time">{{ _time.YYYYMMDD(item.createtime, '-') }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import iconUrl from '@/utils/icon.js'
import * as homeApi from '@/api/home.js'
import { mapGetters } from 'vuex'
export default {
  name: 'Home',
  data() {
    return {
      iconUrl: iconUrl,
      outline: [
        {
          name: '通讯录',
          isEnter: false,
          bgColor: '#ffffff',
          type: 'txlNum',
          num: '',
          img: require('/src/assets/images/home/addressBook.png'),
          enterImg: require('/src/assets/images/home/addressBook-hover.png')
        },
        {
          name: '我的审批',
          num: '',
          isEnter: false,
          bgColor: '#ffffff',
          type: 'spNUm',
          img: require('/src/assets/images/home/myApproval.png'),
          enterImg: require('/src/assets/images/home/myApproval-hover.png')
        },
        {
          name: '我的客户',
          num: '',
          isEnter: false,
          bgColor: '#ffffff',
          type: 'khNum',
          img: require('/src/assets/images/home/client.png'),
          enterImg: require('/src/assets/images/home/client-hover.png')
        },
        {
          name: '我的案件',
          num: '',
          isEnter: false,
          bgColor: '#ffffff',
          type: 'ajNum',
          img: require('/src/assets/images/home/case.png'),
          enterImg: require('/src/assets/images/home/case-hover.png')
        },
        {
          name: '日报审批',
          num: '',
          isEnter: false,
          bgColor: '#ffffff',
          type: 'rbNum',
          img: require('/src/assets/images/home/dailyApproval.png'),
          enterImg: require('/src/assets/images/home/dailyApproval-hover.png')
        },
        {
          name: '未读消息',
          num: '',
          isEnter: false,
          bgColor: '#ffffff',
          type: 'wdNum',
          img: require('/src/assets/images/home/message.png'),
          enterImg: require('/src/assets/images/home/message-hover.png')
        },
        {
          name: '备忘录',
          num: '',
          isEnter: false,
          bgColor: '#ffffff',
          type: 'bwNum',
          img: require('/src/assets/images/home/memo.png'),
          enterImg: require('/src/assets/images/home/memo-hover.png')
        },
        {
          name: '网盘系统',
          num: '',
          isEnter: false,
          bgColor: '#ffffff',
          type: 'wpNum',
          img: require('/src/assets/images/home/dbank.png'),
          enterImg: require('/src/assets/images/home/dbank-hover.png')
        }
      ],
      // 案件信息
      caseType: 0,
      caseData: [],
      // 公告
      announcementList: [],
      // 数据概要
      statisticData: {},
      searchKey: '',
      listParam: {
        search: '',
        statusType: 1,
        page: 1,
        limit: 20
      }
    }
  },
  created() {
    this.init()
    this.$store.dispatch('getDepartmentList')
  },
  computed: {
    ...mapGetters(['userAvatar', 'userName'])
  },
  methods: {
    init() {
      this.getCase()
      this.getAnnouncement()
      this.getStatistics()
    },
    /**
     * @description: 切换案件类型
     * @param {*} type: 案件类型
     */
    changeCaseType(type) {
      this.caseType = type + 1
      this.listParam.statusType = type + 1
      this.getCase()
    },
    /**
     * @description: 获取案件
     */
    getCase() {
      homeApi.getCase(this.listParam).then(res => {
        this.caseData = res.data.data
      })
    },
    /**
     * @description: 获取系统公告
     */
    getAnnouncement() {
      const param = {
        page: 1,
        limit: 20
      }
      homeApi.getAnnouncement(param).then(res => {
        this.announcementList.push(...res.data.data)
      })
    },
    /**
     * @description: 获取数据概要
     */
    getStatistics() {
      homeApi.getStatistics().then(res => {
        for (const key in res.data) {
          this.$set(this.statisticData, key, res.data[key])
        }
      })
    },
    /**
     * @description: 搜索
     */
    search() {
      this.getCase()
    }
  }
}
</script>

<style lang="scss" scoped>
.home-page {
  width: 100%;
}
.title,
.case-title {
  font-size: 1.25rem;
  font-weight: bold;
  color: #354a48;
  line-height: 1;
}
.summary {
  overflow: hidden;
  margin-top: 1.46rem;
  display: grid;
  grid-template-rows: repeat(2, 1fr);
  grid-template-columns: repeat(4, 14.48rem);
  gap: 2.14rem 2.08rem;
  width: 64.22rem;
  height: 20.26rem;
  &-list {
    cursor: pointer;
    position: relative;
    width: 100%;
    height: 100%;
    background: #ffffff;
    border-radius: 0.89rem;
  }

  .list_title {
    position: absolute;
    top: 1.77rem;
    text-align: center;
    width: 8rem;
    font-size: 1.04rem;
    color: #000;
    line-height: 1;
  }
  .list_num {
    position: absolute;
    top: 4.79rem;
    text-align: center;
    width: 8rem;
    font-size: 2.19rem;
    font-weight: bold;
    color: #000000;
  }
  .list_img {
    position: absolute;
    top: 2.55rem;
    right: 1.51rem;
    width: 3.95rem;
    height: 3.95rem;
  }
  .list_active {
    background: linear-gradient(215deg, #1db8ab, #179d92);
    .list_title,
    .list_num {
      color: #fff;
    }
  }
}
.information {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  margin-top: 1.46rem;
  width: 16.2rem;
  height: 20.26rem;
  background: #f8fefe;
  box-shadow: 0rem 1rem 1rem 0rem rgba(192, 209, 208, 0.26);
  border-radius: 0.89rem;
  &-icon {
    z-index: 10;
    cursor: pointer;
    position: absolute;
    top: 1rem;
    right: 1rem;
    width: 1rem;
    height: 1rem;
  }
  &-bgColor {
    position: absolute;
    top: 0;
    width: 16.2rem;
    height: 6.86rem;
    background: linear-gradient(215deg, #1db8ab, #108f84);
    border-radius: 1rem 1rem 0rem 0rem;
  }
  &-avatar {
    z-index: 1;
    margin-top: 3.75rem;
    width: 5.85rem;
    height: 5.85rem;
    border-radius: 50%;
  }
  &-name {
    z-index: 1;
    margin-top: 1.55rem;
    font-size: 1.35rem;
    font-weight: 400;
    color: #1d5c5b;
    line-height: 1;
  }
  &-identity {
    z-index: 1;
    margin-top: 0.68rem;
    font-size: 0.83rem;
    font-weight: 400;
    color: #92a9a8;
    line-height: 1;
  }
}
.case-title {
  margin-top: 1.88rem;
  width: 51.88rem;
}
.case {
  overflow: hidden;
  margin-top: 1.56rem;
  width: 51.88rem;
  height: 30.29rem;
  background: #ffffff;
  box-shadow: 0rem 1rem 1rem 0rem rgba(192, 209, 208, 0.26);
  border-radius: 1rem 1rem 1rem 1rem;
  .but-container-padding {
    padding: 1.1rem 2.24rem 1.1rem 1.1rem;
  }
  .but-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 12rem;
    .but {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 5.42rem;
      height: 1.82rem;
      border-radius: 0.42rem;
      font-size: 0.83rem;
      font-weight: 400;
      line-height: 1;
    }
    .but-active {
      color: #ffffff;
      background: linear-gradient(215deg, #1db8ab, #179d92);
    }
  }
  .search-case {
    position: relative;
    width: 13.29rem;
    height: 1.92rem;
  }
  .search-btn {
    position: absolute;
    right: 0;
    width: 2.5rem;
    height: 1.92rem;
    cursor: pointer;
  }
  .search {
    width: 13.29rem;
    height: 1.92rem;
    background: #ebf0f0;
    border-radius: 0.96rem;
    font-size: 0.83rem;
    border: none;
    padding: 0 2.5rem 0 1rem;
    background-image: url("~@/assets/icon/search.png");
    background-repeat: no-repeat;
    background-size: 1rem;
    background-position: 11.2rem 0.42rem;
  }
  .table {
    width: 50.5rem;
    height: 23.28rem;
    margin: 0 0.68rem 0 0.78rem;
    padding-right: 1.15rem;
    overflow-y: auto;
    &::-webkit-scrollbar {
      width: 8px;
      background-color: #fff;
      border-radius: 4px;
    }
    &::-webkit-scrollbar-thumb {
      background: #e5e7ea;
      border-radius: 4px;
    }
  }
}
.announcement-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 1.88rem;
  width: 27.55rem;
  .title {
    font-size: 1.25rem;
    font-weight: bold;
    color: #354a48;
    line-height: 1;
  }
  span {
    font-size: 0.73rem;
    font-weight: 400;
    color: #999999;
    line-height: 1;
  }
}
.announcement {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 1.56rem;
  width: 27.55rem;
  height: 30.08rem;
  background: #ffffff;
  box-shadow: 0rem 1rem 1rem 0rem rgba(192, 209, 208, 0.26);
  border-radius: 0.52rem;
  .list {
    display: flex;
    align-items: center;
    width: 24.8rem;
    padding: 1.56rem 0;
    border-bottom: 1px solid #dcdcdc;
    .dit {
      display: block;
      width: 0.33rem;
      height: 0.33rem;
      background: #e9323b;
      border-radius: 50%;
    }
    .txt {
      flex-grow: 1;
      padding-left: 0.5rem;
      color: #179e92;
    }
    .time {
      flex-shrink: 0;
      font-size: 0.73rem;
      font-weight: 400;
      color: #999999;
      line-height: 1;
    }
  }
  .list-active {
    .dit {
      opacity: 0;
    }
    .txt {
      color: #000;
      padding-left: 0.5rem;
      width: 20rem;
    }
  }
}
</style>
